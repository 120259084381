import dayjs from 'dayjs/esm'
import qs from 'query-string'
import { computed, ref, watch } from 'vue'
import {
  ActivitiesCategories,
  ActivityItem,
  ApiResponseData,
  CategoryItem,
  Order,
  PurchaseOrder,
  RequestOptions,
} from '/~/types/api'
import { BatchOrder as BatchOrderRawData } from '/~/types/api/order'
import { DateType } from '/~/types/date'
import api from '/~/core/api'
import { EntityProcessor } from '/~/core/processors/entity'
import { createDate, formatDate } from '/~/utils/format/date'
import {
  useBatchOrder,
  useBatchOrderPayeesTable,
} from '/~/composables/batch-order'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useLocalization } from '/~/composables/localization'
import { useLogger } from '/~/composables/logger'
import { Activity } from './core/activity'

const logger = useLogger('activity')

const categories = ref<CategoryItem[]>([])
const categoriesLoaded = ref(false)
const selectedCategory = ref(null)

async function getCategories() {
  if (categoriesLoaded.value) {
    return
  }

  categories.value = [
    {
      label: 'All categories',
      id: null,
    },
  ]

  try {
    const { data } = await api.get<ApiResponseData<ActivitiesCategories[]>>(
      '/v3/activities/categories'
    )

    categories.value = [
      ...categories.value,
      ...data
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((i) => ({
          label: i.label,
          id: i.value,
        })),
    ]
  } catch (error) {
    logger.error(error)
  } finally {
    categoriesLoaded.value = true
  }
}

const { getDatePickerFormat } = useLocalization()
const selectedStartDate = ref<number | null>(null)
const selectedEndDate = ref<number | null>(null)
const datepickerFormatType = ref<DateType>('daymonthyearnumeric')
const datepickerFormat = computed(() =>
  getDatePickerFormat(datepickerFormatType.value)
)

const queryString = computed(() =>
  // backend requires 'DD/MM/YYYY' regardless of the locale
  qs.stringify({
    category: selectedCategory.value || undefined,
    startDate: selectedStartDate.value
      ? createDate(selectedStartDate.value).format('DD/MM/YYYY')
      : undefined,
    endDate: selectedEndDate.value
      ? createDate(selectedEndDate.value).format('DD/MM/YYYY')
      : undefined,
  })
)

const activity = ref(
  new EntityProcessor({
    entity: 'v3/activities',
    perPage: 20,
    mapping: (item: ActivityItem) => new Activity(item),
    queryString: queryString.value,
  })
)

watch(queryString, (value) => {
  activity.value.queryString = value
})

function getContent() {
  activity.value.getData()
}

const isFiltersSelected = computed(() => {
  return (
    selectedCategory.value !== null ||
    selectedStartDate.value !== null ||
    selectedEndDate.value !== null
  )
})

const routeState = computed(() => {
  const query = {}

  if (selectedCategory.value) {
    query.category = selectedCategory.value
  }

  if (selectedStartDate.value) {
    query.startDate = formatDate(
      datepickerFormatType.value,
      selectedStartDate.value
    )
  }

  if (selectedEndDate.value) {
    query.endDate = formatDate(
      datepickerFormatType.value,
      selectedEndDate.value
    )
  }

  return { query }
})

function syncState({ to }) {
  selectedCategory.value = to.query?.category || null
  selectedStartDate.value = to.query?.startDate
    ? Number(createDate(to.query?.startDate, datepickerFormat.value))
    : null
  selectedEndDate.value = to.query?.endDate
    ? Number(createDate(to.query?.endDate, datepickerFormat.value))
    : null
}

function resetFilters() {
  selectedCategory.value = null
  selectedStartDate.value = null
  selectedEndDate.value = null
}

const isActivityEmpty = computed(() => {
  return activity.value.isEmpty
})

export const migratedTypes = {
  order: 'order',
  points: 'points',
}

const recordGroupsByDate = computed(() => {
  const groupsList = {}
  const items = activity.value.hits

  items.forEach((item) => {
    const date = item.happenedAt
    const groupId = formatDate('daymonthyearnumeric', date)
    const groupName = formatDate('daymonthyear', date)
    let groupObj = groupsList[groupId]

    if (!groupObj) {
      groupObj = {
        id: groupId,
        name: groupName,
        records: [],
      }

      groupsList[groupId] = groupObj
    }

    groupObj.records.push(item)
  })

  return Object.fromEntries(
    Object.entries(groupsList).sort((a, b) =>
      dayjs(a).isAfter(dayjs(b)) ? -1 : 1
    )
  )
})

async function getActivityItem(id: string, config: RequestOptions) {
  const url = `/v3/activity/${id}`

  const result = await api.get<ApiResponseData<ActivityItem>>(url, config)
  const activityItem = new Activity(result.data)

  return activityItem
}

async function getOrderItem(url: string) {
  const { data } = await api.get<ApiResponseData<Order>>(url)

  if (!data) {
    return
  }

  return data
}

function getFlowOrderItem(flowType: FlowType | null, number: string) {
  if (flowType === FlowType.purchase) {
    return getPurchaseOrderItem(number)
  } else if (flowType === FlowType.topUpOrder) {
    return getTopUpOrderItem(number)
  } else if (flowType === FlowType.payment) {
    return getPaymentOrderItem(number)
  } else if (flowType === FlowType.statement) {
    return getStatementOrderItem(number)
  } else if (flowType === FlowType.batch) {
    return getBatchOrderItem(number)
  } else {
    return Promise.resolve(undefined)
  }
}

function getPaymentOrderItem(number: string) {
  return getOrderItem(`/v3/payment-orders/${number}`)
}

function getPurchaseOrderItem(number: string) {
  return getOrderItem(`/v3/purchase-orders/${number}`) as Promise<
    PurchaseOrder | undefined
  >
}

function getStatementOrderItem(number: string) {
  return getOrderItem(`/v3/statement-orders/${number}`)
}

function getPointsOrderItem(number: string) {
  return getOrderItem(`/v3/points-program-orders/${number}`)
}

function getQrPayOrderItem(number: string) {
  return getOrderItem(`/v3/qr-pay-orders/${number}`)
}

function getTopUpOrderItem(number: string) {
  return getOrderItem(`/v3/ewallets/topup/${number}`)
}

async function getBatchOrderItem(number: string) {
  const { setBatchOrder } = useBatchOrder()
  const { initBatchOrderPayeesTable } = useBatchOrderPayeesTable()
  const data = await getOrderItem(`/v3/batch-orders/${number}`)

  setBatchOrder(data as BatchOrderRawData)
  initBatchOrderPayeesTable()

  return data
}

export function useActivity() {
  return {
    activity,
    isActivityEmpty,
    recordGroupsByDate,

    getActivityItem,

    categories,
    selectedCategory,
    getCategories,

    selectedStartDate,
    selectedEndDate,
    datepickerFormatType,
    datepickerFormat,

    isFiltersSelected,
    routeState,
    syncState,
    resetFilters,
    getContent,

    getPaymentOrderItem,
    getPurchaseOrderItem,
    getStatementOrderItem,
    getPointsOrderItem,
    getQrPayOrderItem,
    getTopUpOrderItem,
    getBatchOrderItem,
    getFlowOrderItem,
  }
}
