import {
  ActivityItem,
  ActivityItemMetadata,
  ActivityItemPayee,
  ActivityItemPaymentMethod,
} from '/~/types/api'
import { ActivityItemType } from '/~/types/api/activity-enums'
import { ActivityMigratedTypes } from '/~/composables/activity'
import { ActivityStatus } from '/~/composables/activity/core/ActivityStatus'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { ActivityItemPaymentMethodTypeMap } from '/~/composables/payment-methods/payment-methods-types'

export class Activity extends ActivityStatus implements ActivityItem {
  id: string
  description: Array<string>
  points: number
  orderNumber: string
  metadata: ActivityItemMetadata
  createdAt: string
  happenedAt: string
  updatedAt: string
  transactionType: string
  cashback: number
  paymentMethods: ActivityItemPaymentMethod[]
  total: string
  subtotal: string
  payee: ActivityItemPayee
  type: ActivityItemType
  subtype?: string
  status: ActivityItemMetadata['status']
  reference?: string

  pointsTransferRequest?: {
    title: string
    reason: string
    descriptionLabel: string
    descriptionValue: string
  }

  raw: Partial<ActivityItem>

  constructor(raw: Partial<ActivityItem> = {}) {
    const status =
      raw.activity?.status?.toLowerCase() || raw.metadata?.status?.toLowerCase()
    // TODO: check with BE if we still need to support entity_type
    const type = raw.entity_type ?? raw.type?.toLowerCase() ?? 'other'

    super(status, type)

    this.raw = raw
    const { metadata, activity } = raw

    this.metadata = metadata

    this.id = String(raw.id)
    this.type = type

    // TODO: check with BE if we still need to support dates with underscore
    this.createdAt = raw.createdAt ?? raw.created_at
    this.updatedAt = raw.updatedAt ?? raw.updated_at
    this.happenedAt = raw.happenedAt ?? raw.happened_at

    // TODO: check with BE if we still need to support activity, raw.total, metadata.amount, transaction_type, metadata.paidAt, metadata.number, metadata.type
    this.subtotal =
      activity?.subtotal ??
      activity?.display_subtotal ??
      metadata?.subtotal ??
      metadata?.amount

    this.total =
      raw.total ?? metadata?.total ?? metadata?.amount ?? metadata?.subtotal
    this.description = raw.description ?? []
    this.transactionType = raw.transaction_type?.toLowerCase()
    this.cashback = raw.cashback

    this.paidAt = metadata?.paidAt?.date
    this.title = activity?.title
    this.reference = activity?.reference
    this.status = status
    this.fundingStatus = activity?.funding_status
    this.fundingSource = activity?.funding_source
    this.paymentAmount = activity?.payment_amount
    this.externalOrderId = activity?.external_order_id
    this.businessAsset = activity?.business_asset
    this.orderNumber =
      activity?.number ||
      metadata?.orderNumber ||
      metadata?.number ||
      metadata?.refundNumber
    this.userCashbackAmount = activity?.user_cashback_amount
    this.channel = activity?.channel
    this.active = activity?.active
    this.batchOrderType = activity?.type ?? ''

    this.isBusinessBatchOrder = this.batchOrderType === 'business'
    this.isIndividualBatchOrder = this.batchOrderType === 'individual'

    // TODO: check with BE if we still need to support metadata purchase_id, product, merchant, originalPayeeName
    this.purchaseId = metadata?.purchase_id
    this.productName = metadata?.product
    this.merchantName = metadata?.merchant
    this.payee = metadata?.payee ?? {}
    this.originalPayeeName = metadata?.originalPayeeName
    this.month = metadata?.payload?.month
    this.pointSourceName =
      metadata?.payload?.['Point Source Code'] ??
      metadata?.['points source name']
    this.comments =
      metadata?.payload?.comments ??
      metadata?.payload?.Comments ??
      metadata?.comments

    this.subtype = metadata?.type ?? activity?.type

    this.points = activity?.points || metadata?.points || metadata?.pointsSpent
    this.airlinePoints = metadata?.pointsReceived
    this.accountNumber = metadata?.member?.accountNumber

    const { firstName: partnerFirstName, lastName: partnerLastName } =
      metadata?.member ?? {}

    this.partnerName =
      partnerFirstName === partnerLastName
        ? partnerFirstName
        : `${partnerFirstName} ${partnerLastName}`

    this.paymentMethods =
      metadata?.paymentMethods ??
      activity?.displayPaymentMethods ??
      [activity?.payment_method].filter((i) => i) ??
      []

    this.paymentMethods = this.paymentMethods.map((method) => {
      if (!method.type) {
        return method
      }

      method.typeV3 = method.type

      // TODO: change type property
      method.type = ActivityItemPaymentMethodTypeMap[method.type] || method.type

      return method
    })

    this.migratedActivityType = this.getMigratedActivityType(metadata)
    this.pointsTransactions = this.getPointsTransactions(metadata, activity)
    this.campaign = this.getCampaign(metadata)

    this.pointsTransferRequest = this.getPointsTransferRequestDetails(
      metadata,
      this.description[0]
    )
  }

  get refundFailedActivity() {
    return this.metadata?.refundActivities?.find(
      (item) => item.reason === 'payment_failed'
    )
  }

  get isSuccessful() {
    return (
      this.isTypeCampaign ||
      this.isStatusCompleted ||
      this.isTypeMembership ||
      this.isTypeMigratedOrder ||
      this.isTypeMigratedPoints ||
      this.isTypePointsTransaction ||
      (this.isTypeUserCommunication && this.isCommunicationActive)
    )
  }

  get isTypeMembership() {
    return this.type === 'membership'
  }

  get isTypeProgramOrder() {
    return this.type === 'pointsprogramorder'
  }

  get isTypePointsTransaction() {
    return this.type === 'pointstransaction'
  }

  get isTypeUserCashbackOrder() {
    return this.type === 'usercashbackorder'
  }

  get isTypeCashbackReconciliationFeed() {
    return this.type === 'cashbackreconciliationfeed'
  }

  get isTypePaymentOrder() {
    return this.type === 'paymentorder'
  }

  get isTypeBatchOrder() {
    return this.type === 'batchorder'
  }

  get isTypePaymentOrdersGroup() {
    return this.isTypePaymentOrder || this.isTypeBatchOrder
  }

  get isTypePurchaseOrder() {
    return this.type === 'purchaseorder'
  }

  get isTypeRefund() {
    return this.type === 'refund'
  }

  get isTypeStatementOrder() {
    return this.type === 'statementorder'
  }

  get isTypeUserCommunication() {
    return this.type === 'usercommunications'
  }

  get isTypeCampaign() {
    return this.type === 'campaign'
  }

  get isTypeTopUpOrder() {
    return this.type === 'topuporder'
  }

  get isTypeQrcScan() {
    return this.type === 'qrcscan'
  }

  get isTypeQrPayOrder() {
    return this.type === 'qrpayorder'
  }

  get isTypeTopUpTransaction() {
    return this.type === 'topuptransaction'
  }

  get isTypeCashTransaction() {
    return this.type === 'cashtransaction'
  }

  get isTypeMigratedHistory() {
    return this.type === 'migratedhistory'
  }

  get isTypeWithdrawOrder() {
    return this.type === 'withdraworder'
  }

  get flowType() {
    // TODO: replace with ActivityItemType
    switch (this.type) {
      case 'batchorder':
        return FlowType.batch
      case 'statementorder':
        return FlowType.statement
      case 'paymentorder':
        return FlowType.payment
      case 'pointsprogramorder':
        return FlowType.pointsProgramOrder
      case 'topuporder':
        return FlowType.topUpOrder
      case 'purchaseorder':
        return FlowType.purchase
      default:
        return null
    }
  }

  get performedBy() {
    return this.raw.metadata?.performedBy
  }

  get isTypeMigratedPoints() {
    return this.migratedActivityType === ActivityMigratedTypes.points
  }

  get isTypeMigratedOrder() {
    return this.migratedActivityType === ActivityMigratedTypes.order
  }

  get isTypePointsTransferRequest() {
    return this.type === 'pointstransferrequest'
  }

  getPointsTransactions(metadata, activity) {
    const value =
      metadata?.pointsTransaction ??
      activity?.displayPointsTransactions?.map?.((item) => {
        return {
          ...item,
          allocatedValue: item.allocation_value,
        }
      }) ??
      []

    return Array.isArray(value) ? value : [value]
  }

  getCampaign(metadata) {
    const {
      leadNumber,
      lead_number: leadNumberV1,
      email,
      channel,
      organization,
      state,
      reason,
      referenceNumber,
      departure_date: departureDate,
      contactFirstName,
      contact_first_name: contactFirstNameV1,
      contactLastName,
      contact_last_name: contactLastNameV1,
      contactEmail,
      contact_email: contactEmailV1,
      contactPhoneNumber,
      contact_phone_number: contactPhoneNumberV1,
      productsInterested = [],
      package: metadataPackage,
    } = metadata ?? {}

    return {
      number: leadNumber ?? leadNumberV1,
      email,
      channel,
      organization,
      state,
      reason,
      package: metadataPackage,
      departureDate,
      referenceNumber,
      contact: {
        firstName: contactFirstName ?? contactFirstNameV1,
        lastName: contactLastName ?? contactLastNameV1,
        email: contactEmail ?? contactEmailV1,
        phoneNumber: contactPhoneNumber ?? contactPhoneNumberV1,
      },
      productsInterested,
    }
  }

  getMigratedActivityType(metadata) {
    if (
      !this.isTypeMigratedHistory &&
      (this.type !== 'user' || this.transactionType !== 'other')
    ) {
      return null
    } else {
      const { migrated } = metadata ?? {}

      switch (migrated) {
        case 'points':
          return ActivityMigratedTypes.points
        default:
          return ActivityMigratedTypes.order
      }
    }
  }

  getPointsTransferRequestDetails(
    metadata: {
      externalId: string
      type: 'transfer' | 'debit' | 'credit'
      points: number
      reason: string
      status: string
      source: string
      destination: string
    },
    description: string
  ) {
    const typeMappings = {
      transfer: () => {
        const isCredited = description === 'Points credited'

        return {
          title: isCredited ? 'Points Received' : 'Points Sent',
          descriptionLabel: isCredited ? 'Source' : 'Destination',
          descriptionValue: isCredited ? metadata.source : metadata.destination,
        }
      },
      debit: () => ({
        title: 'Points Debited',
        descriptionLabel: 'Destination',
        descriptionValue: metadata.destination,
      }),
      credit: () => ({
        title: 'Points Credited',
        descriptionLabel: 'Source',
        descriptionValue: metadata.source,
      }),
      default: () => ({
        title: 'Points Debited',
        descriptionLabel: 'Destination',
        descriptionValue: 'N/A',
      }),
    }

    const { title, descriptionLabel, descriptionValue } = (
      typeMappings[metadata.type] || typeMappings.default
    )()

    return {
      title,
      reason: metadata.reason ?? 'N/A',
      descriptionLabel,
      descriptionValue: descriptionValue ?? 'N/A',
    }
  }
}
