import {
  ActivityItemMetadataStatus,
  ActivityItemType,
} from '/~/types/api/activity-enums'

export class ActivityStatus {
  status: ActivityItemMetadataStatus
  type: ActivityItemType

  constructor(status: ActivityItemMetadataStatus, type: ActivityItemType) {
    this.status = status
    this.type = type
  }

  get isStatusCompleted() {
    const statuses = [
      ActivityItemMetadataStatus.Completed,
      ActivityItemMetadataStatus.Approved,
      ActivityItemMetadataStatus.Funded,
    ]

    return statuses.includes(this.status)
  }

  get isStatusPending() {
    const statuses = [
      ActivityItemMetadataStatus.Pending,
      ActivityItemMetadataStatus.Processing,
    ]

    return statuses.includes(this.status)
  }

  get isStatusAwaitingFunds() {
    const statuses = [ActivityItemMetadataStatus.AwaitingFundsPi]

    return statuses.includes(this.status)
  }

  get isStatusRemittancePending() {
    const statuses = [ActivityItemMetadataStatus.RemittancePending]

    return statuses.includes(this.status)
  }

  get isStatusGroupPending() {
    return this.isStatusPending || this.isStatusRemittancePending
  }

  get isStatusFailed() {
    const statuses = [
      ActivityItemMetadataStatus.Failed,
      ActivityItemMetadataStatus.Declined,
    ]

    return statuses.includes(this.status)
  }

  get isStatusRefunded() {
    const statuses = [ActivityItemMetadataStatus.Refunded]

    return statuses.includes(this.status)
  }

  get isStatusCompletedWithRemittanceFailures() {
    const statuses = [
      ActivityItemMetadataStatus.CompletedWithRemittanceFailures,
    ]

    return statuses.includes(this.status)
  }

  get isStatusIncomplete() {
    const statuses = [
      ActivityItemMetadataStatus.DestinationFailed,
      ActivityItemMetadataStatus.Incomplete,
    ]

    return statuses.includes(this.status)
  }

  get isStatusCancelled() {
    const statuses = [
      ActivityItemMetadataStatus.Cancelled,
      ActivityItemMetadataStatus.Expired,
    ]

    return statuses.includes(this.status)
  }

  get statusLabel(): { text: string; color: string } {
    if (this.isStatusCompleted) {
      return { text: 'Completed', color: 'text-fg-success' }
    }

    if (this.isStatusPending && this.type === ActivityItemType.Refund) {
      return { text: 'Refund pending', color: 'text-fg-warning' }
    } else if (this.isStatusPending) {
      return { text: 'Pending', color: 'text-fg-warning' }
    }

    if (this.isStatusRemittancePending) {
      return { text: 'Remittance pending', color: 'text-fg-warning' }
    }

    if (this.isStatusAwaitingFunds) {
      return { text: 'Awaiting funds', color: 'text-fg-warning' }
    }

    if (this.isStatusRefunded) {
      return { text: 'Refund completed', color: 'text-fg-error' }
    }

    if (this.isStatusFailed && this.type === ActivityItemType.Refund) {
      return { text: 'Refund failed', color: 'text-fg-error' }
    } else if (this.isStatusFailed) {
      return { text: 'Failed', color: 'text-fg-error' }
    }

    if (this.isStatusCancelled) {
      return { text: 'Cancelled', color: 'text-fg-error' }
    }

    if (this.isStatusIncomplete) {
      return { text: 'Incomplete', color: 'text-fg-error' }
    }

    if (this.isStatusCompletedWithRemittanceFailures) {
      return {
        text: 'Completed with remittance failures',
        color: 'text-fg-error',
      }
    }

    // Default case for undefined or unknown statuses
    return { text: '', color: '' }
  }
}
